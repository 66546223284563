

$("#saveQuoteButton").click(function(event){
    //alert("clicked");
    event.preventDefault();
   // alert("clicked");


    $("#loading").show();
    $.ajax({url: "/quotes/save", type:"GET", success: function(result){

        $("#loading").hide();
        swal("Quote Saved");
        //alert("saved");
    }});
});

