/**
 * Created by jonas on 1/7/16.
 */



$(document).ready(function() {
    var max_fields      = 100; //maximum input boxes allowed
    var warn_fields		= 20;
    var wrapper         = $(".input_fields_wrap"); //Fields wrapper
    var add_button      = $(".add_field_button"); //Add button ID

    var x = 1; //initlal text box count

    $(add_button).click(function(e){ //on add input button click
        e.preventDefault();
        if(x == warn_fields){
            alert("WOW, that's a lot of line items you have there!");
        }
        if(x < max_fields){ //max input box allowed
            x++; //text box increment
            $(wrapper).append('' +
                '<div>' +
                    '<a href="#" class="remove_field">Remove</a>' +
                    '<input style="width: 25%;" type="text"  name="newPName[]"/>' +
                    '<input style="width: 50%;" type="text"  name="newPValue[]"/>' +

                '</div>'); //add input box

        }
    });

    $(wrapper).on("click",".remove_field", function(e){ //user click on remove text
        e.preventDefault(); $(this).parent('div').remove(); x--;
        calcprice();
    })
});
